.help-page {
  p {
    margin: 0;
  }

  .help-search {
    background: url('../../layout/images/pages/bg-help.png') repeat;
    padding: 0;
    text-align: center;

    .help-search-content {
      padding: 5rem 12rem;
    }

    .search-container {
      font-size: 1.5rem;
      padding: 1rem;
      position: relative;

      input {
        appearance: none;
        font-size: 1.5rem;
        padding: .5rem;
        text-indent: 2.5rem;
        width: 100%;
      }

      i {
        color: $textColor;
        margin-left: 1rem;
        margin-top: -.5rem;
        position: absolute;
        top: 50%;
        width: 2rem;
      }
    }
  }

  .status-bars {
    margin-top: 1rem;
    @include flex();

    .status-bar {
      background: #8BC34A;
      -ms-flex: 1 1 0;
      flex: 1 1 0;
      height: 50px;
      margin-right: .25rem;
      transition: transform $transitionDuration;

      &:last-child {
        margin-right: 0;
      }

      &.status-bar-failure {
        background: #EC407A;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .status-bar-footer {
    padding: 1rem 0 0 0;
    @include flex();
    @include flex-justify-between();
  }

  .blog-post {
    border-radius: 4px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    height: 150px;
    margin: 3rem 2rem;
    position: relative;

    &:last-child {
      margin-bottom: 1rem;
    }

    img {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .blog-text {
      left: 20px;
      position: absolute;
      top: 30px;

      h1 {
        color: #FFF;
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      span {
        color: #FFF;
        font-weight: 600;
      }
    }

    .blog-profile {
      left: -25px;
      position: absolute;
      top: -25px;

      img {
        border-radius: 50%;
        height: 50px;
        width: 50px;

      }
    }

    &:nth-child(1) {
      background-image: url('../../layout/images/pages/help/blog1.jpg');
    }

    &:nth-child(2) {
      background-image: url('../../layout/images/pages/help/blog2.jpg');
    }

    &:nth-child(3) {
      background-image: url('../../layout/images/pages/help/blog3.jpg');
    }
  }
}

@media screen and (max-width: 991px) {
  .help-page {
    .help-search {
      .help-search-content {
        padding: 6rem 2rem;
      }
    }
  }
}
