/* You can add global styles to this file, and also import other style files */

@import 'primeng/resources/primeng.min.css';
@import 'primeflex/primeflex';
@import 'primeicons/primeicons.css';
// @import '~prismjs/themes/prism-coy.css';
// @import 'assets/flags/flags.css';

@import 'assets/theme/theme-grey';
@import 'assets/layout/scss/layout-grey';

@import 'scss/inputs';
@import 'scss/tree-table';
@import 'scss/tree';
