.postman {
  account-postman-sms {
    .p-tabview-panels {
      .surface-section {
        .surface-title {
          font-size: 18px;
          font-weight: 600;
        }

        .surface-list {
          &-phone {
            background: #F8F9FA;
            border: none;
          }
        }
      }
    }
  }
}
