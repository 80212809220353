.layout-wrapper {
  #layout-megamenu-button {
    color: $megamenuButtonTextColor;
    font-weight: 600;
    height: 60px;
    left: 290px;
    letter-spacing: 2px;
    line-height: 60px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    width: 140px;
    @include transition(background-color $transitionDuration);

    &:hover {
      background-color: $topbarIconHoverBgColor;
    }

    i {
      font-size: 1em;
      height: 15px;
      margin-right: 2px;
      text-align: center;
      vertical-align: middle;
      width: 15px;
    }
  }

  .layout-megamenu {
    background-color: $megamenuBgColor;
    border: solid 1px $megamenuBorderColor;
    display: none;
    font-weight: normal;
    left: 290px;
    letter-spacing: 0;
    list-style-type: none;
    max-width: 700px;
    padding: 16px;
    padding-bottom: 20px;
    position: fixed;
    text-align: left;
    text-transform: none;
    top: 60px;
    z-index: 998;

    .layout-megamenu-submenu {
      overflow: hidden;
      padding: 0;

      .content-img {
        box-sizing: border-box;
        padding: 16px;
        position: relative;

        img {
          width: 135px;
        }

        .img-header {
          color: $darkMegamenuTextColor;
          font-size: 13px;
          font-weight: 700;
          line-height: 14px;
          position: absolute;
          text-shadow: 0 0 2px $darkMegamenuHeaderShadowColor;
          top: 20px;
        }

        .img-subheader {
          color: #FFF;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          position: absolute;
          text-shadow: 0 0 2px $darkMegamenuHeaderShadowColor;
          top: 40px;
        }

        .p-button {
          border-radius: 3px;
          margin-top: 10px;

          .p-button-text {
            padding: 4px 16px;
          }
        }
      }

      h6 {
        color: $megamenuHeaderColor;
        font-weight: 700;
        line-height: 20px;
        margin: 20px 0 0 14px;
        text-align: left;
      }

      ul {
        list-style-type: none;
        padding: 0;

        li {
          border-left: 4px solid transparent;
          line-height: 35px;

          i {
            color: $megamenuIconColor;
            display: inline-block;
            font-size: inherit;
            margin-left: 10px;
            margin-right: 12px;
            vertical-align: middle;
          }

          span {
            color: $megamenuTextColor;
            display: inline-block;
            vertical-align: middle;
          }

          &:hover {
            background-color: $megamenuHoverBgColor;
          }

          &:active {
            border-left: 4px solid $megamenuActiveTextColor;
            color: $megamenuActiveTextColor;

            span {
              color: $megamenuActiveTextColor;
            }

            i {
              color: $megamenuActiveTextColor;
            }
          }
        }
      }
    }

    &.layout-megamenu-dark {
      background-color: $darkMegamenuBgColor;
      border: none;

      h6 {
        color: $darkMegamenuTextColor;
      }

      ul {
        li {
          i {
            color: $darkMegamenuIconColor;
          }

          span {
            color: $darkMegamenuTextColor;
          }

          &:hover {
            background-color: $darkMegamenuHoverBgColor;
          }

          &:active {
            border-left: 4px solid $darkMegamenuHoverTextColor;
            color: $darkMegamenuHoverTextColor;

            span {
              color: $darkMegamenuHoverTextColor;
            }

            i {
              color: $darkMegamenuHoverTextColor;
            }
          }
        }
      }
    }

    &.layout-megamenu-gradient {
      background-image: linear-gradient(to top, $gradientMegamenuBottomBgColor, $gradientMegamenuTopBgColor);
      border: none;
    }

    &.layout-megamenu-active {
      display: block;
    }
  }
}

@media screen and (max-width: $mobileBreakPoint) {
  .layout-wrapper {
    #layout-megamenu-button {
      background-color: $megamenuMobileBgColor;
      font-weight: 600;
      height: 45px;
      left: 0;
      letter-spacing: 2px;
      line-height: 45px;
      margin-top: 60px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      &:hover {
        background-color: $megamenuMobileHoverBgColor;
      }
    }

    .layout-megamenu {
      height: calc(100% - 105px);
      left: 0;
      max-width: 100%;
      overflow: auto;
      top: 105px;
      width: 100%;
    }
  }
  body.body-megamenu-active {
    overflow: hidden;
  }
}
