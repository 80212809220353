.layout-config {
  background: #FFF;
  border-left: 0 none;
  height: 100%;
  overflow: auto;
  padding: 1rem;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform .4s cubic-bezier(.05, .74, .2, .99);
  width: 16em;
  z-index: 999;

  &.layout-config-active {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    transform: translateX(0);

    .layout-config-content {
      .layout-config-button {
        i {
          transform: rotate(360deg);
        }
      }
    }
  }

  p {
    color: $textSecondaryColor;
    line-height: 1.5rem;
  }

  .p-col-6 {
    .p-field-radiobutton {
      margin-bottom: 0;
    }
  }

  .layout-themes {
    @include flex();
    @include flex-wrap(wrap);

    > div {
      padding: .25rem;
    }

    a {
      border-radius: $borderRadius;
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
      display: block;
      height: 2.5rem;
      position: relative;
      @include flex-align-center();
      @include flex-justify-center();
      transition: transform $transitionDuration;
      width: 2.5rem;

      i {
        color: #FFF;
        font-size: 1rem;
        left: 50%;
        margin-left: -.5rem;
        margin-top: -.5rem;
        position: absolute;
        top: 50%;
      }

      img {
        height: 2.5rem;
        width: 2.5rem;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.layout-config-button {
  background: $topbarRightBgColor;
  border-bottom-left-radius: $borderRadius;
  border-top-left-radius: $borderRadius;
  box-shadow: -.25rem 0 1rem rgba(0, 0, 0, .15);
  color: $topbarTextColor;
  cursor: pointer;
  display: block;
  height: 3rem;
  line-height: 3rem;
  margin-top: -1.5rem;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: center;
  top: 50%;
  transition: background-color $transitionDuration;
  width: 3rem;
  z-index: 999;

  i {
    font-size: 2rem;
    line-height: inherit;
    transform: rotate(0deg);
    transition: transform 1s;
  }

  &:hover {
    background: lighten($topbarRightBgColor, 5%);
  }
}
