.layout-wrapper {
  .layout-sidebar {
    background-color: $sidebarBgColor;
    border-right: solid 1px $sidebarBorderColor;
    bottom: 0;
    left: 0;
    position: fixed;
    top: 60px;
    width: 230px;
    z-index: 999;
    @include border-box();
    @include transition(left $transitionDuration);
    @if variable-exists(menuBgImage) {
      background-image: url('../images/special/#{$menuBgImage}');
      background-size: 240px 100%;
      background-repeat: no-repeat;
    }

    .sidebar-scroll-content {
      height: 100%;
      overflow: auto;
    }

    .user-profile {
      color: $textColor;
      text-align: center;

      #sidebar-profile-button {
        padding: 12px 0;

        img {
          display: block;
          margin-left: 70px;
        }
      }

      button,
      a {
        color: $textColor;
        display: block;
        position: relative;

        .sidebar-profile-name {
          display: inline-block;
          font-weight: 700;
        }

        .sidebar-profile-role {
          color: darken($textSecondaryColor, 25%);
          display: block;
        }

        &:hover {
          background-color: $sidebarMenuitemHoverBgColor;
        }

        .layout-menuitem-toggler {
          position: absolute;
          right: 12px;
          top: 11px;
        }

      }
    }


    &.layout-sidebar-dark {
      background-color: $darkSidebarBgColor;
      border-right-color: $darkSidebarBgColor;
      @if variable-exists(darkMenuBgImage) {
        background-image: url('../images/special/#{$darkMenuBgImage}');
        background-size: 240px 100%;
        background-repeat: no-repeat;
      }

      .user-profile {
        color: $darkSidebarMenuitemTextColor;

        a {
          color: $darkSidebarMenuitemTextColor;

          &:hover {
            background-color: $darkSidebarMenuitemHoverBgColor;
          }

          .sidebar-profile-role {
            color: $darkSidebarMenuitemTextColor;
          }
        }

        li.menuitem-active {
          > a {
            color: $darkSidebarMenuitemActiveTextColor;

            i {
              color: $darkSidebarMenuitemActiveIconColor;
            }
          }
        }
      }

      .layout-menu {
        li {
          > a {
            color: $darkSidebarMenuitemTextColor;

            i {
              color: $darkSidebarMenuitemIconColor;
            }

            &:hover {
              background-color: $darkSidebarMenuitemHoverBgColor;
            }
          }

          &.active-menuitem {
            > a {
              color: $darkSidebarMenuitemActiveTextColor;

              i {
                color: $darkSidebarMenuitemActiveIconColor;
              }
            }
          }
        }

        > li {
          > a {
            border-bottom: 1px solid $darkSidebarMenuitemDividerColor;
          }

          &.active-menuitem {
            > a {
              border-left: 4px solid $darkSidebarMenuitemActiveBorderLeftColor;
              color: $darkSidebarMenuitemActiveTextColor;
              padding-left: 8px;

              i {
                color: $darkSidebarMenuitemActiveIconColor;
              }
            }

            > ul {
              border-bottom: 1px solid $darkSidebarMenuitemDividerColor;
            }
          }

          &:first-child {
            > a {
              border-top: 1px solid $darkSidebarMenuitemDividerColor;
            }
          }
        }
      }
    }

    &.layout-sidebar-gradient {
      border-right-color: transparent;
      @if variable-exists(gradientMenuBgImage) {
        background-image: url('../images/special/#{$gradientMenuBgImage}');
        background-size: 240px 100%;
        background-repeat: no-repeat;
      } @else {
        @include background-gradient-top2bottom($gradientSidebarTopBgColor,
          $gradientSidebarBottomBgColor);
        border-right-color: $gradientSidebarTopBgColor;
      }

      .layout-menu {
        li {
          > a {
            color: $gradientSidebarMenuitemTextColor;

            i {
              color: $gradientSidebarMenuitemIconColor;
            }

            &:hover {
              background-color: $gradientSidebarMenuitemHoverBgColor;
            }
          }

          &.active-menuitem {
            > a {
              color: $gradientSidebarMenuitemActiveTextColor;

              i {
                color: $gradientSidebarMenuitemActiveIconColor;
              }
            }
          }
        }

        > li {
          > a {
            border-bottom: 1px solid $gradientSidebarMenuitemDividerColor;
          }

          &.active-menuitem {
            > a {
              border-left: 4px solid $gradientSidebarMenuitemActiveBorderLeftColor;
              color: $gradientSidebarMenuitemActiveTextColor;
              padding-left: 8px;

              i {
                color: $gradientSidebarMenuitemActiveIconColor;
              }
            }

            > ul {
              border-bottom: 1px solid $gradientSidebarMenuitemDividerColor;
            }
          }

          &:first-child {
            > a {
              border-top: 1px solid $gradientSidebarMenuitemDividerColor;
            }
          }
        }
      }
    }
  }

  &.layout-wrapper-sidebar-inactive {
    .layout-sidebar {
      left: -230px;
    }
  }

  &.layout-wrapper-overlay-sidebar {
    .layout-sidebar {
      left: -230px;
    }

    &.layout-wrapper-overlay-sidebar-active {
      .layout-sidebar {
        left: 0;
      }
    }
  }
}

@media screen and (min-width: $mobileBreakPoint+1) {
  .layout-wrapper {
    &.layout-wrapper-slim-sidebar {
      .layout-topbar {
        .logo {
          margin-left: 30px;
        }
      }

      .layout-sidebar {
        overflow: visible;
        width: 50px;

        .user-profile {
          #sidebar-profile-button {
            padding: 11px 4px 11px 3px;
            text-align: center;

            img {
              margin: auto;
              width: 30px;
            }

            .sidebar-profile-name {
              display: none;
            }

            .sidebar-profile-role {
              display: none;
            }
          }

          .usermenu {
            background-color: $sidebarBgColor;
            border: 1px solid $sidebarBorderColor;
            color: $sidebarMenuitemTextColor;
            display: none;
            left: 54px;
            max-height: 450px;
            min-width: 250px;
            overflow: auto;
            position: absolute;
            top: 0;
            @include border-radius($borderRadius);
            @include shadow(0 3px 6px 0 rgba(0, 0, 0, .16));

            li {
              border-left: 0 none;
              border-right: 0 none;

              &:first-child {
                margin: 0;
              }

              &:last-child {
                margin: 0;
              }

              a {
                color: $sidebarMenuitemTextColor;
                @include transition(background-color $transitionDuration);

                i {
                  color: $sidebarMenuitemIconColor;
                }

              }

              &.menuitem-active {
                > a {
                  color: $sidebarMenuitemTextColor;

                  span {
                    color: $sidebarMenuitemIconColor;
                  }

                  i {
                    color: $sidebarMenuitemIconColor;
                  }
                }
              }
            }

            &.usermenu-active {
              display: block;
            }
          }
        }

        .layout-menu {
          > li {
            position: relative;

            > a {
              border-left: 4px solid transparent;
              color: $sidebarMenuitemTextColor;
              display: block;
              padding: 12px 14px 12px 9px;
              text-align: center;

              i {
                color: $sidebarMenuitemIconColor;
                font-size: 14px;
                margin-right: 0;
              }

              span {
                display: none;

                &.menuitem-badge {
                  display: block;
                  font-size: 10px;
                  height: 13px;
                  position: absolute;
                  right: -20px;
                  top: 6px;
                  width: 13px;

                  &.text-badge {
                    display: none;
                  }
                }
              }

              &:hover + .layout-menu-tooltip {
                display: block;
              }

              .layout-menuitem-toggler {
                display: none;
              }
            }

            > ul {
              background-color: $sidebarBgColor;
              border: 1px solid $sidebarBorderColor;
              color: $sidebarMenuitemTextColor;
              left: 54px;
              min-width: 200px;
              position: absolute;
              top: 0;
              @include border-radius($borderRadius);
              @include shadow(0 0 10px 0 rgba(0, 0, 0, .16));

              li {
                > a {
                  padding: 12px;
                }
              }
            }

            &.active-menuitem {
              > a {
                border-left: 4px solid $sidebarMenuitemActiveBorderLeftColor;

                i {
                  color: $sidebarMenuitemActiveIconColor;
                }

                &:hover + .layout-menu-tooltip {
                  display: none;
                }
              }
            }
          }

          .layout-menu-tooltip {
            display: none;
            left: 50px;
            line-height: 1;
            padding: 0 5px;
            position: absolute;
            top: 9px;

            .layout-menu-tooltip-text {
              background-color: #212121;
              color: #FFF;
              font-size: 14px;
              min-width: 75px;
              padding: 6px 8px;
              text-align: center;
              white-space: nowrap;
            }

            .layout-menu-tooltip-arrow {
              border-color: transparent;
              border-right-color: #212121;
              border-style: solid;
              border-width: 5px 5px 5px 0;
              height: 0;
              left: 0;
              margin-top: -5px;
              position: absolute;
              top: 50%;
              width: 0;
            }
          }
        }

        &.layout-sidebar-dark {
          .user-profile {
            .usermenu {
              background-color: $darkSidebarBgColor;
              border: $darkSidebarBgColor;
              color: $darkSidebarMenuitemTextColor;

              > li {
                a {
                  color: $darkSidebarMenuitemTextColor;

                  i {
                    color: $darkSidebarMenuitemIconColor;
                  }

                }

                &.menuitem-active {
                  > a {
                    color: $darkSidebarMenuitemTextColor;

                    span {
                      color: $darkSidebarMenuitemTextColor;
                    }

                    i {
                      color: $darkSidebarMenuitemIconColor;
                    }
                  }
                }
              }
            }
          }

          .layout-menu {
            > li {
              > a {
                border-bottom: 1px solid $darkSidebarMenuitemDividerColor;
                color: $darkSidebarMenuitemTextColor;

                i {
                  color: $darkSidebarMenuitemIconColor;
                }

                &:hover {
                  background-color: $darkSidebarMenuitemHoverBgColor;
                }
              }

              &.active-menuitem {
                > a {
                  border-left: 4px solid $darkSidebarMenuitemActiveBorderLeftColor;
                  color: $darkSidebarMenuitemActiveTextColor;

                  i {
                    color: $darkSidebarMenuitemActiveIconColor;
                  }
                }
              }

              &:first-child {
                > a {
                  border-top: 1px solid $darkSidebarMenuitemDividerColor;
                }
              }

              > ul {
                background-color: $darkSidebarBgColor;
                border-color: $darkSidebarBgColor;
                color: $darkSidebarMenuitemTextColor;
              }
            }
          }
        }

        &.layout-sidebar-gradient {
          .user-profile {
            .usermenu {
              @include background-gradient-top2bottom($gradientSidebarTopBgColor,
                $gradientSidebarBottomBgColor);
              border-color: $gradientSidebarTopBgColor;
              color: $gradientSidebarMenuitemTextColor;

              li {
                a {
                  color: $gradientSidebarMenuitemTextColor;

                  i {
                    color: $gradientSidebarMenuitemIconColor;
                  }

                }

                &.menuitem-active {
                  > a {
                    color: $gradientSidebarMenuitemTextColor;

                    span {
                      color: $gradientSidebarMenuitemTextColor;
                    }

                    i {
                      color: $gradientSidebarMenuitemIconColor;
                    }
                  }
                }
              }
            }
          }

          .layout-menu {
            > li {
              > a {
                border-bottom: 1px solid $gradientSidebarMenuitemDividerColor;
                color: $gradientSidebarMenuitemTextColor;

                i {
                  color: $gradientSidebarMenuitemIconColor;
                }

                &:hover {
                  background-color: $gradientSidebarMenuitemHoverBgColor;
                }
              }

              &.active-menuitem {
                > a {
                  border-left: 4px solid $gradientSidebarMenuitemActiveBorderLeftColor;
                  color: $gradientSidebarMenuitemActiveTextColor;

                  i {
                    color: $gradientSidebarMenuitemActiveIconColor;
                  }
                }
              }

              &:first-child {
                > a {
                  border-top: 1px solid $gradientSidebarMenuitemDividerColor;
                }
              }

              > ul {
                @include background-gradient-top2bottom($gradientSidebarTopBgColor,
                  $gradientSidebarBottomBgColor);
                border-color: $gradientSidebarTopBgColor;
                color: $gradientSidebarMenuitemTextColor;
              }
            }
          }
        }

      }

      .layout-main {
        margin-left: 50px;
      }
    }


    &.layout-wrapper-horizontal-sidebar {
      .layout-topbar {
        position: static;

        .logo {
          margin-left: 30px;
        }
      }

      .layout-sidebar {
        min-height: 36px;
        overflow: visible;
        padding: 0;
        position: static;
        width: 100%;
        @if variable-exists(horizontalMenuBgImageLight) {
          background-image: url('../images/special/#{$horizontalMenuBgImageLight}');
          background-size: auto;
          background-repeat: no-repeat;
          background-color: $horizontalMenuBgColor;
        }

        .layout-menu {
          border-bottom: 1px solid $sidebarMenuitemDividerColor;
          margin: 0;
          padding: 0;

          > li {
            border-left: 0 none;
            border-right: 0 none;
            display: inline-block;
            padding: 0;
            position: relative;
            width: auto;

            &:first-child {
              border-left: 1px solid $sidebarMenuitemDividerColor;

              > a {
                border-top: 0 none;
              }
            }

            > a {
              border-bottom: 0 none;
              border-left: 4px solid transparent;
              border-right: 1px solid $sidebarMenuitemDividerColor;
              margin-bottom: -4px;
              min-height: 38px;
              padding: 9px 12px;

              i {
                color: $routeBarIconColor;
              }

              span {
                margin: 0 8px;
              }

              .menuitem-badge {
                display: inline-block;
                float: none;
                margin-right: 0;
              }

              .layout-menuitem-toggler {
                position: static;
              }
            }

            > ul {
              background-color: $sidebarBgColor;
              border: 1px solid $sidebarBorderColor;
              color: $sidebarMenuitemTextColor;
              left: -1px;
              margin: 0;
              max-height: 450px;
              min-width: 200px;
              overflow: auto;
              position: absolute;
              top: 38px;
              z-index: 1;
              @include shadow(0 3px 6px 0 rgba(0, 0, 0, .16));
              @include border-radius($borderRadius);

              li {
                > a {
                  border-left: 4px solid transparent;
                }
              }
            }

            &.active-menuitem {
              > a {
                border-left-color: transparent;

                i {
                  color: $sidebarMenuitemActiveIconColor;
                }

                &:hover + .layout-menu-tooltip {
                  display: none;
                }
              }
            }
          }
        }

        &.layout-sidebar-dark {
          @if variable-exists(horizontalMenuBgImageDark) {
            background-image: url('../images/special/#{$horizontalMenuBgImageDark}');
            background-color: $horizontalDarkMenuBgColor;
          }

          .layout-menu {
            border-bottom: 1px solid $darkSidebarMenuitemDividerColor;

            > li {
              &:first-child {
                border-left: 1px solid $darkSidebarMenuitemDividerColor;
              }

              > a {
                border-right: 1px solid $darkSidebarMenuitemDividerColor;
                color: $darkSidebarMenuitemTextColor;
                padding-left: 12px;

                i {
                  color: $darkSidebarMenuitemIconColor;
                }

                &:hover {
                  background-color: $darkSidebarMenuitemHoverBgColor;
                }
              }

              &.active-menuitem {
                > a {
                  border-left: 4px solid transparent;
                  color: $darkSidebarMenuitemActiveTextColor;

                  i {
                    color: $darkSidebarMenuitemActiveIconColor;
                  }
                }
              }

              > ul {
                background-color: $darkSidebarBgColor;
                border-color: $darkSidebarBgColor;
                color: $darkSidebarMenuitemTextColor;

                li.active-menuitem {
                  > a {
                    border-left: 4px solid $darkSidebarMenuitemActiveTextColor;
                    color: $darkSidebarMenuitemActiveTextColor;

                    i {
                      color: $darkSidebarMenuitemActiveIconColor;
                    }
                  }
                }
              }
            }
          }
        }

        &.layout-sidebar-gradient {
          @if variable-exists(horizontalMenuBgImageGradient) {
            background-image: url('../images/special/#{$horizontalMenuBgImageGradient}');
            background-color: $horizontalGradientMenuBgColor;
          }

          .layout-menu {
            border-bottom: 1px solid $gradientSidebarMenuitemDividerColor;

            > li {
              &:first-child {
                border-left: 1px solid $gradientSidebarMenuitemDividerColor;
              }

              > a {
                border-right: 1px solid $gradientSidebarMenuitemDividerColor;
                color: $gradientSidebarMenuitemTextColor;

                i {
                  color: $gradientSidebarMenuitemIconColor;
                }

                &:hover {
                  background-color: $gradientSidebarMenuitemHoverBgColor;
                }
              }

              &.active-menuitem {
                > a {
                  border-left: 4px solid transparent;
                  color: $gradientSidebarMenuitemActiveTextColor;

                  i {
                    color: $gradientSidebarMenuitemActiveIconColor;
                  }
                }
              }

              > ul {
                @include background-gradient-top2bottom($gradientSidebarTopBgColor,
                  $gradientSidebarBottomBgColor);
                border-color: $gradientSidebarTopBgColor;
                color: $gradientSidebarMenuitemTextColor;

                li.active-menuitem {
                  > a {
                    border-left: 4px solid $gradientSidebarMenuitemActiveTextColor;
                    color: $gradientSidebarMenuitemActiveTextColor;

                    i {
                      color: $gradientSidebarMenuitemActiveIconColor;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .layout-main {
        margin-left: 0;
        padding-top: 0;
      }
    }

    &.layout-wrapper-horizontal-sidebar,
    &.layout-wrapper-slim-sidebar {
      .layout-topbar {
        #menu-button {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: $mobileBreakPoint) {
  .layout-wrapper {
    .layout-sidebar {
      left: -230px;
      top: 64px;
      @include shadow(2px 0 1px 0 rgba(0,
        0,
        0,
        .16));
    }

    &.layout-wrapper-sidebar-mobile-active {
      .layout-sidebar {
        left: 0;
      }
    }
  }
}
