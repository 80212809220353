.promotions-new {
  .p-steps {
    margin-top: 10px;

    li {
      &.p-highlight {
        a {
          .p-steps-number {
            color: #FFF;
            background: $buttonBg;
          }
        }
      }

      a {
        .p-steps-number {
          cursor: pointer;
        }
      }
    }
  }

  p-card {
    .p-card-content {
      .uploaded-banner {
        border-radius: 4px;
        height: 210px;
        width: 100%;
      }
    }
    .confirmation-list {
      li {
        font-size: 14px;
      }
    }

    .p-card-content {
      .field {
        margin-bottom: 0;

        label {
          margin-bottom: 10px;
        }

        #dates {
          p-calendar {
            margin-top: 15px;

            @media (min-width: 768px) {
              margin-left: 15px;
              margin-top: 0;
            }
          }
        }
      }

      promo-infos {
        .p-float-label {
          input,
          textarea {
            width: 100%;
          }

          p-editor {
            .p-editor-content {
              height: 140px !important;
            }
          }
        }
      }

      image-picker {
        form {
          display: block !important;
        }
      }
    }
  }
}

.promotions {
  .p-tabview {
    &-panels {
      .p-toggleable-content {
        .p-panel-content {
          background: #FFF;
        }
      }
    }
  }
}
