menu {
  margin: 0;
  padding: 0;

  .layout-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      > ul {
        @include transition(max-height .5s cubic-bezier(0, 1, 0, 1));
        max-height: 0;
        overflow: hidden;
      }

      .active {


        i.layout-menuitem-toggler {
          @include rotate(90deg);
        }

        + ul {
          @include transition(max-height 1s ease-in-out);
          // background-color: $sidebarMenuitemDividerColor;
          max-height: 10000px;

          .active {
            border-left: 4px solid $sidebarMenuitemActiveBorderLeftColor;

            span {
              margin-left: -4px;
            }
          }
        }
      }

    }

  }


  .p-link,
  a {
    align-items: center;
    border-bottom: 1px solid $sidebarMenuitemDividerColor;
    color: $sidebarMenuitemTextColor;
    cursor: pointer;
    display: flex;
    padding: 8px 12px;
    position: relative;
    user-select: none;
    width: 100%;

    i {
      margin-right: 9px;
    }

    span {
      flex: 1 0 auto;
    }

    &.active {
      color: $sidebarMenuitemActiveTextColor;

      i {
        color: $sidebarMenuitemActiveTextColor;
      }
    }

    &:hover {
      background-color: $sidebarMenuitemHoverBgColor;
    }
  }

  .menuitem-badge {
    background-color: $badgeBgColor;
    border-radius: 50px;
    color: $badgeTextColor;
    float: right;
    height: 20px;
    margin-left: 0;
    margin-right: 25px;
    text-align: center;
    width: 20px;

    &.text-badge {
      margin-right: 22px;
      width: 40px;
    }
  }

}


@media screen and (min-width: $mobileBreakPoint+1) {

  .layout-menu {
    ul {
      li {
        a {
          padding-left: 20px;
        }

        li {
          a {
            padding-left: 40px;
          }

          li {
            a {
              padding-left: 60px;
            }

          }
        }
      }
    }
  }
}
