// overview
$overviewTextColor: #FFF;

// stats
$statsBgColor: #FFF;
$statsBorderColor: #D6D7D9;
$statsIconBgColor: #FFF;
$statsSubtitleTextColor: #00BFE7;
$statsSubtitleColor: #AEB0B5;

// weekly tasks
$addtaskBgColor: #F6F6F5;
$tasksIconColor: #AEB0B5;
$daytagBgColor: #E4E2E0;

// resolution center
$estimationTextColor: #AEB0B5;
$estimatedHourTextColor: #E59393;

// Team
$teamContentHoverBgColor: #F2F3F3;
$teamPrimaryTextColor: #212121;
$teamSecondaryTextColor: #AEB0B5;
$contactIconBgColor: #0872B9;
$contactIconColor: #FFF;

// Chat
$messagefromBorderColor: #4AA564;
$messageownBorderColor: #E31C3D;
$messageBgColor: #F4F4F4;
$messageTimeTextColor: #AEB0B5;
$messageBorderColor: #D6D7D9;
$messageIconColor: #D6D7D9;


// daily
$salesHoverBgColor: #F2F2F2;
$salesColumnHeaderColor: #424242;
$salesRowTextColor: #212121;
$salesUpIconColor: #4CAF50;
$salesDownIconColor: #F44336;

// timeline
$timelineSeperatorColor: #DDD;
$loveNotificationBgColor: #F8DEDE;
$loveNotificationTextColor: #CD2026;
$checkedNotificationBgColor: #E1F3F8;
$checkedNotificationTextColor: #00A6D2;
$eventNotificationBgColor: #FFF1D2;
$eventNotificationTextColor: #F39F19;
$notificationTimeColor: #5B616B;

// profile
$profileTextColor: #212121;

.dashboard {
  .overview-box {
    color: $overviewTextColor;
    min-height: 120px;
    position: relative;

    i {
      float: right;
      font-size: 21px;
      position: absolute;
      right: 10px;
      top: 10px;
    }

    img {
      bottom: 0;
      position: absolute;
      right: 0;
      width: 90px;
    }

    .overview-title {
      font-size: 16px;
      letter-spacing: .8px;
      line-height: 20px;
      margin-bottom: 5px;
      text-align: left;
    }

    .overview-numbers {
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 8px;
      text-align: left;
    }

    .overview-ratios {
      font-size: 14px;
      line-height: 18px;
      opacity: .95;
      text-align: left;
    }

    &.monthly-sales,
    &.monthly-views,
    &.monthly-users,
    &.security-view,
    &.preferences-view {
      border-radius: 4px;
      box-sizing: border-box;
      height: 100%;
      padding: 10px 10px 10px 20px;
      position: relative;
      width: 100%;
    }

    &.monthly-sales {
      @include background-gradient-with-degree(240deg, #FF7295, #FE875D);
    }

    &.monthly-views {
      @include background-gradient-with-degree(240deg, #00AADF, #0F7377);
    }

    &.monthly-users {
      @include background-gradient-with-degree(90.68deg, #13547A 0%, #074366 100.07%);
    }

    &.security-view {
      @include background-gradient-with-degree(90deg, #FDA085 0%, #F6D365 100%);
    }

    &.preferences-view {
      @include background-gradient-with-degree(90deg, #EC7871 0%, #D25271 100%);
    }

    &.monthly-users,
    &.security-view,
    &.preferences-view {
      .badge-default {
        background: #FFF;
        border-radius: 4px;
        color: #495057;
        line-height: 1.2;
        padding: .3rem 1rem;
      }

      .person-name {
        font-size: 25px;
        font-weight: 700;
      }
    }

    &.agents-box {
      @include background-gradient-with-degree(90deg, #FF2D20 0%, #E8190D 100%);
    }
  }

  .stats {
    @include border-box();
    background-color: $statsBgColor;
    border-bottom: solid 1px $statsBorderColor;
    border-left: solid 1px $statsBorderColor;
    border-right: solid 1px $statsBorderColor;
    border-top: 7px solid $statsTopBorderColor;
    margin-top: 29px;
    min-height: 120px;
    position: relative;
    text-align: center;

    .stats-icon {
      background: $statsIconBgColor;
      display: inline-block;
      height: 7px;
      padding: 0 5px;
      position: relative;
      top: -7px;

      img {
        margin-bottom: -10px;
        position: relative;
        top: -10px;
      }
    }

    .message-icon {
      height: 34px;
      width: 44px;
    }

    .doc-icon {
      height: 34px;
      width: 25px;
    }

    .mark-icon {
      height: 34px;
      width: 25px;
    }

    img.profile-icon {
      /*top: -15px;
          margin-bottom: -5px;*/
      height: 34px;
      width: 34px;
    }

    .stats-numbers {
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
    }

    .stats-title {
      font-size: 17px;
      line-height: 21px;
    }

    .stats-subtitle {
      color: $statsSubtitleColor;
      font-size: 14px;
      line-height: 18px;

      span {
        color: $statsSubtitleTextColor;
      }
    }
  }

  .p-panel {
    .p-panel-title {
      margin: 0;
    }

    .p-panel-content {
      padding: 17px;
    }
  }

  .weekly-tasks {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 8px 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }

        .p-checkbox {
          margin-right: 6px;
          vertical-align: middle;
        }

        > span {
          vertical-align: middle;
        }

        .weekly-task-status {
          float: right;

          i {
            color: $textSecondaryColor;
            font-size: 13px;
            margin-left: 6px;
            width: 16px;
          }

          .day {
            background-color: $daytagBgColor;
            color: darken($tasksIconColor, 50%);
            display: inline-block;
            min-width: 60px;
            text-align: center;
          }
        }
      }
    }
  }

  .resolution-center {
    padding-bottom: 8px;

    .p-inputtext {
      display: block;
    }

    .p-outputlabel {
      vertical-align: middle;
    }

    .p-button {
      display: block;
      margin-top: 5px;
    }
  }

  .team {
    .p-panel-content {
      padding: 0;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        @include clearfix();
        border-left: 3px solid rgba(0, 0, 0, 0);
        padding: 16px;

        &:hover {
          background-color: $teamContentHoverBgColor;
          border-color: $teamContentHoverBorderColor;
        }

        .team-box {
          display: inline-block;
          vertical-align: middle;
        }

        .team-member {
          color: $teamPrimaryTextColor;
          display: block;
          font-weight: 600;
          text-align: left;
        }

        img {
          display: inline-block;
          height: 35px;
          margin-right: 6px;
          vertical-align: middle;
          width: 35px;
        }

        a {
          background-color: $contactIconBgColor;
          border-radius: 100%;
          display: block;
          float: right;
          height: 30px;
          line-height: 30px;
          margin-left: 6px;
          margin-top: 4px;
          text-align: center;
          width: 30px;

          i {
            color: $contactIconColor;
            font-size: 22px;
            line-height: inherit;
          }
        }
      }
    }
  }

  .chart {
    .p-panel-content {
      overflow: auto;
    }
  }

  .chat {
    margin-top: 20px;

    .p-panel-content {
      padding: 0;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        @include clearfix();
        font-size: 0;
        padding: 16px 0;

        .message-meta {
          @include border-box();
          display: inline-block;
          font-size: $fontSize;
          padding: 8px;
          text-align: left;
          vertical-align: top;
          width: 160px;

          img {
            display: block;
            height: 48px;
            width: 48px;
          }

          .name {
            font-weight: 600;
          }

          .time {
            color: $teamSecondaryTextColor;
            display: block;
          }
        }

        .message {
          @include border-box();
          background-color: $messageBgColor;
          display: inline-block;
          font-size: $fontSize;
          padding: 12px;
          vertical-align: top;
          width: calc(100% - 160px);
        }

        &.message-from {
          .message-meta {
            border-left: 4px solid $messagefromBorderColor;

            img {
              float: left;
              margin-right: 8px;
            }
          }
        }

        &.message-own {
          .message-meta {
            border-right: 4px solid $messageownBorderColor;

            img {
              float: right;
              margin-left: 8px;
            }
          }
        }
      }
    }

    .write-message {
      border-top: 1px solid $messageBorderColor;
      margin-top: 10px;
      position: relative;

      i {
        color: $messageIconColor;
        font-size: 24px;
        left: 8px;
        margin-top: -14px;
        position: absolute;
        top: 50%;
      }

      input {
        @include border-box();
        border: none;
        font-size: 14px;
        height: 40px;
        padding-left: 36px;
        width: 100%;

        &:focus {
          outline: 0 none;
        }
      }
    }
  }

  .daily-sales {
    margin-top: 20px;

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
    }

    thead {
      background-color: #FFF;
      text-align: right;

      th {
        padding: 8px 16px;
      }
    }

    tbody {
      tr:nth-child(even) {
        background: #FFF;
      }

      tr:nth-child(odd) {
        background: #F2F2F2;
      }

      td {
        border-color: transparent;
        padding: 8px 16px;
      }
    }

    tr {
      th {
        color: $salesColumnHeaderColor;
        font-weight: 600;
      }
    }

    td {
      color: $salesRowTextColor;
      text-align: right;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        button:first-child {
          margin-right: 4px;
        }
      }

      img {
        height: 26px;
        padding: 8px;
        vertical-align: middle;
        width: 26px;
      }

      .pi-angle-up {
        color: $salesUpIconColor;
        font-size: 22px;
        padding-left: 10px;
      }

      .pi-angle-down {
        color: $salesDownIconColor;
        font-size: 22px;
        padding-left: 10px;
      }
    }
  }

  .schedule {
    margin-top: 20px;
  }

  .timeline {
    &.p-panel {
      .p-panel-content {
        position: relative;

        .timeline-line {
          background: $timelineSeperatorColor;
          height: 100%;
          left: 50%;
          margin-left: -1px;
          position: absolute;
          top: 0;
          width: 1px;
        }
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      position: relative;

      li {
        display: block;
        margin-bottom: 30px;
        position: relative;
        width: 100%;

        .timeline-message {
          overflow: hidden;
          position: relative;
          width: 45%;

          img {
            height: auto;
            position: absolute;
            width: 100%;
            z-index: 0;
          }

          span {
            display: block;
            padding: 15px;
            position: relative;
            z-index: 10;

            &.love {
              background-color: $loveNotificationBgColor;
              color: $loveNotificationTextColor;
              font-weight: 600;
              text-align: right;
            }

            &.checked {
              background-color: $checkedNotificationBgColor;
              color: $checkedNotificationTextColor;
              font-weight: 600;
            }

            &.event {
              background-color: $eventNotificationBgColor;
              color: $eventNotificationTextColor;
              font-weight: 600;
            }
          }
        }

        .timeline-icon {
          line-height: 30px;
          z-index: 100;

          img {
            height: 30px;
            width: 30px;
          }

          span {
            color: $notificationTimeColor;
            font-weight: 600;
          }
        }

        &.odd {
          .timeline-icon {
            left: 50%;
            margin-left: -16px;
            position: absolute;

            span {
              float: right;
            }

            img {
              float: left;
              margin-right: 15px;
            }
          }

          .timeline-message {
            float: left;

            span {
              padding: 15px;
              text-align: right;
            }
          }
        }

        &.even {
          .timeline-icon {
            margin-right: -16px;
            position: absolute;
            right: 50%;
            text-align: right;

            span {
              float: left;
            }

            img {
              float: right;
              margin-left: 15px;
            }
          }

          .timeline-message {
            float: right;

            span {
              padding: 15px;
            }
          }
        }
      }
    }
  }

  .inventory-list {
    .p-panel-content {
      padding: 0;
    }
  }

  .profile-details {
    .p-panel-content {
      padding: 0;
      text-align: center;
    }

    img {
      width: 100%;
    }

    .profile-avatar {
      height: 120px;
      position: relative;
      top: -60px;
      width: 120px;
    }

    .profile-name {
      color: $profileTextColor;
      display: block;
      font-weight: 600;
      margin-top: -75px;
      text-align: center;
    }

    .profile-role {
      color: $profileTextColor;
      display: block;
      text-align: center;
    }

    .p-button {
      margin-top: 16px;
    }

    .profile-statistics-panel {
      display: inline-block;
      position: relative;
      text-align: center;

      .profile-statistics {
        display: block;
        font-size: 16px;
        font-weight: 600;
      }

      .statistics-title {
        border-bottom: 4px solid transparent;
        padding-bottom: 10px;
      }
    }
  }

  .product-badge {
    border-radius: 2px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .3px;
    padding: .25em .5rem;
    text-transform: uppercase;

    &.status-instock {
      background: #C8E6C9;
      color: #256029;
    }

    &.status-outofstock {
      background: #FFCDD2;
      color: #C63737;
    }

    &.status-lowstock {
      background: #FEEDAF;
      color: #8A5340;
    }
  }
}
