html {
  font-size: $fontSize;
  height: 100%;
}

body {
  background-color: $bodyBgColor;
  color: $textColor;
  font-family: $fontFamily;
  font-size: $fontSize;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  min-height: 100%;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;

  a {
    color: $topbarLeftBgColor;
    text-decoration: none;
  }
}
