
.p-splitter {
  border: 1px solid #3f4b5b;
  background: #2a323d;
  border-radius: 4px;
  color: rgba(255, 255, 255, .87);
}
.p-splitter .p-splitter-gutter {
  transition: color .15s, box-shadow .15s;
  background: rgba(255, 255, 255, .04);
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: #3f4b5b;
}
.p-splitter .p-splitter-gutter-resizing {
  background: #3f4b5b;
}
