.req::after {
  color: #900;
  content: '*';
  padding-left: 2px;
}

field label:first-child {
  display: block;
  margin-bottom: 1rem;
}

.p-inputgroup .p-inputgroup-addon.pre {
  + p-calendar .p-inputtext,
  + p-dropdown .p-dropdown {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
