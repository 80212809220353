$primaryColor: #004141;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: #064447;
$primaryDarkerColor: #0C5458;
$primaryTextColor: #FFF;

$highlightBg: scale-color($primaryColor, $lightness: 25%);
$highlightTextColor: #495057;

@import '../sass/theme/theme';
