.layout-footer {
  background-color: $footerBgColor;
  padding: 20px 25px;

  h3 {
    color: #FFF;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    text-align: left;
  }

  p {
    color: #9FAEBF;
    display: block;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 10px;
    opacity: .9;
  }

  a {
    color: #9FAEBF;

    i {
      font-size: 24px;
      line-height: 24px;
      margin-left: 30px;
      padding: 0;
      text-align: right;
    }

    &:hover {
      color: #FFF;
    }
  }

  .footer-right {
    padding-top: 25px;
    text-align: right;
  }
}
