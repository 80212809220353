// @include tablet {
* {
  &::-webkit-scrollbar-track {
    background-color: #DDD;
  }

  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    @include border-radius(8px);
    background-color: $primaryColor;
    border: 2px solid #DDD;

    &:hover {
      background-color: lighten($primaryColor, 5%);
    }
  }

}

///}
