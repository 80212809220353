.p-panel {
  .p-panel-header {
    background: $panelHeaderBg;
    border: $panelHeaderBorder;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    color: $panelHeaderTextColor;
    padding: $panelHeaderPadding;

    .p-panel-title {
      font-weight: $panelHeaderFontWeight;
    }

    .p-panel-header-icon {
      @include action-icon();
    }
  }

  &.p-panel-toggleable {
    .p-panel-header {
      padding: $panelToggleableHeaderPadding;
    }

    .p-panel-content{
      .p-toggleable-content {
          overflow: visible;
      }
    }
  }

  .p-panel-content {
    background: #FAFAFF;
    border: $panelContentBorder;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    border-top: 0 none;
    color: $panelContentTextColor;
    padding: $panelContentPadding;
    position: relative;
  }

  .p-panel-footer {
    background: $panelFooterBg;
    border: $panelFooterBorder;
    border-top: 0 none;
    color: $panelFooterTextColor;
    padding: $panelFooterPadding;
  }
}
