.login-body {
  .login-topbar {
    @include background-gradient-diagonal($topbarLeftBgColor, $topbarRightBgColor);
    height: 60px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    .logo img {
      height: 33px;
    }
  }

  .login-panel {
    padding-top: 60px;
  }

  .card-container {
    border-top: 5px solid $primaryColor;
    max-width: 370px;
  }
}

@media screen and (min-width: 992px) {
  .login-body {
    .card-container {
      max-width: 640px;
    }
  }
}
