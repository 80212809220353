.usermenu {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;

  &.usermenu-overlay {
    background: $topbarRightBgColor;
    display: none;
    position: fixed;
    width: 200px;
    z-index: 999;
    @include shadow(0 2px 4px 0 rgba(0, 0, 0, .5));

    li {
      a {
        &:hover {
          background-color: $topbarIconHoverBgColor;
        }
      }
    }
  }

  &.usermenu-active {
    display: block;
    left: auto;
    right: 0;
    top: 60px;
  }

  li {
    button,
    a {
      color: $topbarTextColor;
      display: block;
      padding: 6px 12px;
      text-align: left;
      width: 100%;

      i {
        margin-right: 9px;
        vertical-align: middle;

        &.layout-menuitem-toggler {
          @include transition(transform $transitionDuration);
        }
      }

      span,
      img {
        display: inline-block;
        vertical-align: middle;
      }

      &:hover {
        background-color: $sidebarMenuitemHoverBgColor;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      max-height: 0;
      overflow: hidden;
      padding: 0;

      a {
        padding-left: 24px;

        span, img {
          vertical-align: middle;
        }

        img {
          margin-right: 8px;
        }
      }
    }
  }

  > li {
    &.menuitem-active {
      > a {
        border-left: 4px solid $sidebarMenuitemActiveBorderLeftColor;
        color: $sidebarMenuitemActiveTextColor;
        padding-left: 8px;

        i {
          color: $sidebarMenuitemActiveTextColor;
        }

        span {
          color: $sidebarMenuitemActiveTextColor;
        }

        i.layout-menuitem-toggler {
          @include rotate(-180deg);
        }
      }

      > ul {
        max-height: 1000px;
      }
    }
  }
}
