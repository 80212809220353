$fontFamily: 'Source Sans Pro', Arial, sans-serif;
$fontSize: 14px;
$borderRadius: 0;
$dividerColor: #D6D7D9;
$textColor: #212121;
$textSecondaryColor: #797979;
$transitionDuration: .2s;
$mobileBreakPoint: 1024px;

/*Badge*/
$badgeBgColor: #4AA564;
$badgeTextColor: #FFF;

/* RouteBar */
$routeBarBgColor: #FFF;
$routeBarBorderColor: #D6D7D9;
$routeBarIconColor: #5B616A;
$routeBarIconHoverBgColor: #F2F2F2;
