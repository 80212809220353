.widget-overview-box {
  color: #FFF;
  min-height: 120px;
  position: relative;

  i {
    float: right;
    font-size: 21px;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  img {
    bottom: 0;
    position: absolute;
    right: 0;
    width: 90px;
  }

  .overview-title {
    font-size: 16px;
    letter-spacing: .8px;
    line-height: 20px;
    margin-bottom: 5px;
    text-align: left;
  }

  .overview-numbers {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 8px;
    text-align: left;
  }

  .overview-ratios {
    font-size: 14px;
    line-height: 18px;
    opacity: .95;
    text-align: left;
  }

  &.monthly-sales {
    @include background-gradient-with-degree(240deg, #FF7295, #FE875D);
    box-sizing: border-box;
    height: 100%;
    padding: 10px 10px 10px 20px;
    position: relative;
    width: 100%;
  }

  &.monthly-views {
    @include background-gradient-with-degree(240deg, #00AADF, #0F7377);
    box-sizing: border-box;
    height: 100%;
    padding: 10px 10px 10px 20px;
    position: relative;
    width: 100%;
  }

  &.monthly-users {
    @include background-gradient-with-degree(240deg, #4B7ECF, #8E70EE);
    box-sizing: border-box;
    height: 100%;
    padding: 10px 10px 10px 20px;
    position: relative;
    width: 100%;
  }
}

.widget-stats {
  @include border-box();
  background-color: #FFF;
  border-bottom: solid 1px #D6D7D9;
  border-left: solid 1px #D6D7D9;
  border-right: solid 1px #D6D7D9;
  border-top: 7px solid $statsTopBorderColor;
  margin-top: 29px;
  min-height: 120px;
  position: relative;
  text-align: center;

  .stats-icon {
    background: #FFF;
    display: inline-block;
    height: 7px;
    padding: 0 5px;
    position: relative;
    top: -7px;

    img {
      margin-bottom: -10px;
      position: relative;
      top: -10px;
    }
  }

  .message-icon {
    height: 34px;
    width: 44px;
  }

  .doc-icon {
    height: 34px;
    width: 25px;
  }

  .mark-icon {
    height: 34px;
    width: 25px;
  }

  img.profile-icon {
    /*top: -15px;
        margin-bottom: -5px;*/
    height: 34px;
    width: 34px;
  }

  .stats-numbers {
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
  }

  .stats-title {
    font-size: 17px;
    line-height: 21px;
  }

  .stats-subtitle {
    color: #AEB0B5;
    font-size: 14px;
    line-height: 18px;

    span {
      color: #00BFE7;
    }
  }
}

.widget-timeline {
  .timeline-wrapper {
    position: relative;

    .timeline-line {
      background: #DDD;
      height: 100%;
      left: 50%;
      margin-left: -1px;
      position: absolute;
      top: 0;
      width: 1px;
    }

    ul {
      list-style-type: none;
      padding: 0;
      position: relative;

      li {
        display: block;
        margin-bottom: 30px;
        position: relative;
        width: 100%;

        .timeline-message {
          overflow: hidden;
          position: relative;
          width: 45%;

          img {
            height: auto;
            position: absolute;
            width: 100%;
            z-index: 0;
          }

          span {
            display: block;
            padding: 15px;
            position: relative;
            z-index: 10;

            &.love {
              background-color: #F8DEDE;
              color: #CD2026;
              font-weight: 600;
              text-align: right;
            }

            &.checked {
              background-color: #E1F3F8;
              color: #00A6D2;
              font-weight: 600;
            }

            &.event {
              background-color: #FFF1D2;
              color: #F39F19;
              font-weight: 600;
            }
          }
        }

        .timeline-icon {
          line-height: 30px;
          z-index: 100;

          img {
            height: 30px;
            width: 30px;
          }

          span {
            color: #5B616B;
            font-weight: 600;
          }
        }

        &.odd {
          .timeline-icon {
            left: 50%;
            margin-left: -16px;
            position: absolute;

            span {
              float: right;
            }

            img {
              float: left;
              margin-right: 15px;
            }
          }

          .timeline-message {
            float: left;

            span {
              padding: 15px;
              text-align: right;
            }
          }
        }

        &.even {
          .timeline-icon {
            margin-right: -16px;
            position: absolute;
            right: 50%;
            text-align: right;

            span {
              float: left;
            }

            img {
              float: right;
              margin-left: 15px;
            }
          }

          .timeline-message {
            float: right;

            span {
              padding: 15px;
            }
          }
        }
      }
    }
  }
}

.widget-chat {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      @include clearfix();
      font-size: 0;
      padding: 16px 0;

      .message-meta {
        @include border-box();
        display: inline-block;
        font-size: $fontSize;
        padding: 8px;
        text-align: left;
        vertical-align: top;
        width: 160px;

        img {
          display: block;
          height: 48px;
          width: 48px;
        }

        .name {
          font-weight: 600;
        }

        .time {
          color: $teamSecondaryTextColor;
          display: block;
        }
      }

      .message {
        @include border-box();
        background-color: #F4F4F4;
        display: inline-block;
        font-size: $fontSize;
        padding: 12px;
        vertical-align: top;
        width: calc(100% - 160px);
      }

      &.message-from {
        .message-meta {
          border-left: 4px solid #4AA564;

          img {
            float: left;
            margin-right: 8px;
          }
        }
      }

      &.message-own {
        .message-meta {
          border-right: 4px solid #E31C3D;

          img {
            float: right;
            margin-left: 8px;
          }
        }
      }
    }
  }

  .write-message {
    border-top: 1px solid #D6D7D9;
    margin-top: 10px;
    position: relative;

    i {
      color: #D6D7D9;
      font-size: 24px;
      left: 8px;
      margin-top: -14px;
      position: absolute;
      top: 50%;
    }

    input {
      @include border-box();
      border: none;
      font-size: 14px;
      height: 40px;
      padding-left: 36px;
      width: 100%;

      &:focus {
        outline: 0 none;
      }
    }
  }
}

.widget-profile {
  .p-panel-content {
    padding: 0;
    text-align: center;
  }

  img {
    width: 100%;
  }

  .profile-avatar {
    height: 120px;
    position: relative;
    top: -60px;
    width: 120px;
  }

  .profile-name {
    color: #212121;
    display: block;
    font-weight: 600;
    margin-top: -75px;
    text-align: center;
  }

  .profile-role {
    color: #212121;
    display: block;
    text-align: center;
  }

  .p-button {
    margin-top: 16px;
  }

  .profile-statistics-panel {
    display: inline-block;
    position: relative;
    text-align: center;

    .profile-statistics {
      display: block;
      font-size: 16px;
      font-weight: 600;
    }

    .statistics-title {
      border-bottom: 4px solid transparent;
      padding-bottom: 10px;
    }
  }
}

.widget-team {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      @include clearfix();
      border-left: 3px solid rgba(0, 0, 0, 0);
      padding: 16px;

      &:hover {
        background-color: #F2F3F3;
        border-color: #F2F3F3;
      }

      .team-box {
        display: inline-block;
        vertical-align: middle;
      }

      .team-member {
        color: #212121;
        display: block;
        font-weight: 600;
        text-align: left;
      }

      img {
        display: inline-block;
        height: 35px;
        margin-right: 6px;
        vertical-align: middle;
        width: 35px;
      }

      a {
        background-color: #0872B9;
        border-radius: 100%;
        display: block;
        float: right;
        height: 30px;
        line-height: 30px;
        margin-left: 6px;
        margin-top: 4px;
        text-align: center;
        width: 30px;

        i {
          color: #FFF;
          font-size: 22px;
          line-height: inherit;
        }
      }
    }
  }
}

.widget-resolution-center {
  padding-bottom: 8px;

  .p-inputtext {
    display: block;
  }

  .p-outputlabel {
    vertical-align: middle;
  }

  .p-button {
    display: block;
    margin-top: 5px;
  }
}

.widget-pricing-box {
  text-align: center;

  .pricing-header {
    background-color: #122E51;
    color: #FFF;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 12px 0;
  }

  .pricing-subheader {
    background-color: #EFEFEF;
  }

  .price {
    color: #122E51;
    display: block;
    font-size: 32px;
    font-weight: 600;
  }

  .duration {
    color: #4773AA;
    display: block;
    font-size: 18px;
    font-weight: 600;
  }

  .pricing-content {
    background-color: #FFF;
    min-height: 250px;
    padding-top: 20px;

    span {
      display: block;
      padding: 2px 0;
      text-align: center;
    }
  }

  .p-button {
    position: relative;
    top: -55px;
  }

  &.pricing-basic {
    .pricing-header {
      background-color: #00A6D2;
    }

    .price {
      color: #00A6D2;
    }

    .duration {
      color: #00A6D2;
    }
  }
}
