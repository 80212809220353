.exception-body {
  .exception-topbar {
    height: 60px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    @include border-box();
    z-index: 99999;
    @include background-gradient-diagonal($topbarLeftBgColor, $topbarRightBgColor);

    .logo {
      @include border-box();
      display: inline-block;
      padding: 10px 100px;
      text-align: center;
      width: 230px;

      img {
        height: 40px;
      }
    }
  }

  .exception-image {
    position: absolute;
    top: 60px;
    width: 100%;
  }

  .exception-panel {
    @include border-box();
    padding: 24% 0 0 100px;
    width: 500px;

    .exception-icon {
      max-height: 75px;
      max-width: 75px;
    }
  }
}

@media screen and (max-width: 640px) {
  .exception-body {
    .exception-panel {
      padding: 200px 25px 0 25px;
      text-align: center;
      width: 100%;
    }
  }
}
