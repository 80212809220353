$bodyBgColor: #FFF;
$topbarLeftBgColor: #43474E;
$topbarRightBgColor: #43474E;
$topbarIconHoverBgColor: rgba(255, 255, 255, .1);
$topbarTextColor: #FFF;
$sidebarBorderColor: #D6D7D9;
$footerBgColor: #242527;
$statsTopBorderColor: #43474E;
$teamContentHoverBorderColor: #43474E;

$sidebarBgColor: #FFF;
$sidebarMenuitemTextColor: #212121;
$sidebarMenuitemDividerColor: #E4E2E0;
$sidebarMenuitemIconColor: #5B616A;
$sidebarMenuitemHoverBgColor: #F2F2F2;
$sidebarMenuitemActiveBorderLeftColor: #0F7377;
$sidebarMenuitemActiveTextColor: #0F7377;
$sidebarMenuitemActiveIconColor: #0F7377;

$darkSidebarBgColor: #242527;
$darkSidebarMenuitemTextColor: #FFF;
$darkSidebarMenuitemDividerColor: rgba(255, 255, 255, .075);
$darkSidebarMenuitemIconColor: #FFF;
$darkSidebarMenuitemHoverBgColor: rgba(255, 255, 255, .1);
$darkSidebarMenuItemHoverTextColor: #0A9DFF;
$darkSidebarMenuitemActiveBorderLeftColor: #0A9DFF;
$darkSidebarMenuitemActiveTextColor: #0A9DFF;
$darkSidebarMenuitemActiveIconColor: #0A9DFF;

$gradientSidebarTopBgColor: #1B1E22;
$gradientSidebarBottomBgColor: #5B616B;
$gradientSidebarMenuitemTextColor: #FFF;
$gradientSidebarMenuitemDividerColor: rgba(255, 255, 255, .075);
$gradientSidebarMenuitemIconColor: #FFF;
$gradientSidebarMenuitemHoverBgColor: rgba(255, 255, 255, .1);
$gradientSidebarMenuItemHoverTextColor: #0A9DFF;
$gradientSidebarMenuitemActiveBorderLeftColor: #0A9DFF;
$gradientSidebarMenuitemActiveTextColor: #0A9DFF;
$gradientSidebarMenuitemActiveIconColor: #0A9DFF;

$megamenuButtonTextColor: #FFF;
$megamenuBgColor: #FFF;
$megamenuBorderColor: #D6D7D9;
$megamenuTextColor: #5B616A;
$megamenuHeaderColor: #205493;
$megamenuIconColor: #5B616A;
$megamenuHoverBgColor: #F2F2F2;
$megamenuActiveTextColor: #0F7377;
$megamenuMobileBgColor: #5B616B;
$megamenuMobileHoverBgColor: #43474E;

$darkMegamenuBgColor: #242527;
$darkMegamenuTextColor: #FFF;
$darkMegamenuHeaderShadowColor: #000;
$darkMegamenuIconColor: #FFF;
$darkMegamenuHoverBgColor: rgba(255, 255, 255, .1);
$darkMegamenuHoverTextColor: #0A9DFF;

$gradientMegamenuTopBgColor: #1B1E22;
$gradientMegamenuBottomBgColor: #5B616B;

@import '../../sass/layout/layout';
