.tree-table {
  background: #FFF;
  font-size: 12px;
  overflow: visible;
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border-color: #ccc;
    border-style: solid;
    border-width: 1px;
    color: #111;
    padding: 10px;


    &:not(:last-child) {
      border-right: none;
    }
  }

  thead {
    background: #FFF;
    border-bottom: 2px solid #666;

    tr {
      font-size: 12px;
      font-weight: bold;

      &:first-child th:first-child {
        padding-left: 15px;
      }
    }
  }

  tbody {
    tr {

      td {
        &:first-child {
          padding-left: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .tree-table {
    display: block;
    overflow-x: auto;
  }
}

