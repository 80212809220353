.layout-wrapper {
  .layout-topbar {
    @include background-gradient-diagonal($topbarLeftBgColor, $topbarRightBgColor);
    @include border-box();
    height: 60px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 998;

    .logo img {
      height: 33px;
    }

    .usermenu {
      a {
        position: relative;

        .layout-menuitem-toggler {
          position: absolute;
          right: 12px;
          top: 11px;
        }
      }

      > li {
        &.menuitem-active {
          > a {
            i {
              color: $topbarTextColor;
            }

            span {
              color: $topbarTextColor;
            }
          }
        }
      }
    }

    #menu-button {
      @include transition(background-color $transitionDuration);
      color: $topbarTextColor;
      font-size: 30px;
      height: 60px;
      left: 230px;
      line-height: 60px;
      position: absolute;
      text-align: center;
      width: 60px;

      &:hover {
        background-color: $topbarIconHoverBgColor;
      }

      i {
        font-size: 28px;
        line-height: inherit;
      }
    }

    #right-sidebar-button {
      @include transition(background-color $transitionDuration);
      color: $topbarTextColor;
      display: inline-block;
      font-size: 30px;
      height: 60px;
      line-height: 60px;
      position: absolute;
      right: 0;
      text-align: center;
      width: 60px;

      &:hover {
        background-color: $topbarIconHoverBgColor;
      }

      i {
        font-size: 28px;
        line-height: inherit;
      }
    }

    #topbar-menu-button {
      color: $topbarTextColor;
      display: block;
      height: 40px;
      position: absolute;
      right: 80px;
      top: 10px;

      img {
        display: inline-block;
        height: 40px;
        vertical-align: middle;
        width: 40px;
      }

      span {
        display: inline-block;
        vertical-align: middle;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: $mobileBreakPoint) {
    .layout-topbar {
      #menu-button {
        line-height: 56px;
        order: 1;
        position: static;

        i {
          font-size: 18px;
        }
      }

      .logo {
        order: 2;
        padding: 0;
      }

      .topbar-preference {
        order: 3;
        width: 60px;

        a {
          color: $badgeTextColor;
          height: 40px;
          margin: auto;
          width: 40px;

          &.active {
            background-color: $highlightTextColor;
            border-radius: 50%;
          }
        }

        i {
          font-size: 18px;
        }
      }

      #topbar-menu-button {
        right: 65px;

        span {
          display: none;
        }
      }

      #right-sidebar-button {
        right: 0;
      }
    }

  }

  @media screen and (max-width: $mobileBreakPoint * 0.5) {
    .layout-topbar {
      #right-sidebar-button {
        width: 48px;
      }

      #topbar-menu-button {
        right: 48px;
      }
    }
  }

}
