#layout-right-sidebar {
  @include transition(right $transitionDuration);
  background-color: $sidebarBgColor;
  border-left: solid 1px $sidebarBorderColor;
  height: 100%;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: fixed;
  right: -231px;
  top: 60px;
  width: 230px;
  z-index: 999;

  .right-panel-scroll-content {
    padding-bottom: 150px;
  }

  &.layout-right-sidebar-active {
    display: block;
    right: 0;
  }

  .p-tabview {
    padding: 0;

    .p-tabview-panels {
      padding: 0;
    }

    .p-tabview-nav {
      li {
        padding: 0 4px;
      }

      .p-tabview-left-icon {
        font-size: 20px;
        margin-right: 0;
      }
    }
  }

  .submenu-title {
    background-color: #F2F2F2;
    border-bottom: 1px solid #D6D7D9;
    padding: 4px 0 4px 11px;

    span {
      color: #212121;
      font-size: 16px;
      font-weight: 600;
      line-height: 42px;
    }
  }

  .submenu-content {
    list-style-type: none;

    &.settings {
      padding: 0 11px;

      .p-grid {
        padding: 20px 10px;

        .p-col-6 {
          margin: 10px;
        }
      }
    }

    ul {
      padding: 0;

      li {
        display: inline-block;
        padding: 11px;
      }
    }

    img {
      float: left;
      height: 27px;
      margin-right: 8px;
      width: 27px;
    }

    span {
      color: #212121;
      display: block;
      font-size: 12px;

      &.messager-name {
        font-weight: 600;
      }

      &.message-text {
        float: right;
        width: 83%;
      }

      &.time-stamp {
        color: #80848B;
        margin-top: 30px;
        position: absolute;
        text-align: center;
        width: 10%;
      }
    }

    .calendar-event-date {
      background-color: #0F7377;
      box-sizing: border-box;
      display: block;
      float: left;
      font-size: 14px;
      font-weight: 600;
      height: 48px;
      margin-right: 8px;
      padding: 6px 0 0;
      text-align: center;
      width: 48px;

      span {
        display: inline-block;
        width: 100%;

        &.date {
          color: #FFF;
        }
      }
    }

    .calendar-event-detail {
      display: block;
      float: left;

      .calendar-event-title {
        display: block;
        font-weight: 600;
      }

      i {
        display: block;
        margin-right: 4px;
        margin-top: 2px;
      }

      .material-icons {
        display: inline-block;
        font-size: 17px;
        vertical-align: middle;
      }

      .calendar-event-location {
        display: inline-block;
        left: -4px;
        top: -6px;
        vertical-align: middle;
      }
    }

    .order-settings {
      background-color: #E5F0F8;
      color: #205493;
      height: 74px;
      text-align: center;
      width: 80px;

      &:hover {
        box-shadow: 0 0 8px #AEB0B5;
      }

      i {
        font-size: 30px;
      }

      span {
        color: #205493;
        font-weight: 600;
      }
    }

    .sales-settings {
      background-color: #D5E6D8;
      color: #2E8540;
      height: 74px;
      text-align: center;
      width: 80px;

      &:hover {
        box-shadow: 0 0 8px #AEB0B5;
      }

      i {
        font-size: 30px;
      }

      span {
        color: #2E8540;
        font-weight: 600;
      }
    }

    .shipment-settings {
      background-color: #FEF0D2;
      color: #E3A51B;
      height: 74px;
      text-align: center;
      width: 80px;

      &:hover {
        box-shadow: 0 0 8px #AEB0B5;
      }

      i {
        font-size: 30px;
      }

      span {
        color: #E3A51B;
        font-weight: 600;
      }
    }

    .visitors-settings {
      background-color: #FCE8EB;
      color: #CD2026;
      height: 74px;
      text-align: center;
      width: 80px;

      &:hover {
        box-shadow: 0 0 8px #AEB0B5;
      }

      i {
        font-size: 30px;
      }

      span {
        color: #CD2026;
        font-weight: 600;
      }
    }

    .history-settings {
      background-color: #DBD4E9;
      color: #4C2C92;
      height: 74px;
      text-align: center;
      width: 80px;

      &:hover {
        box-shadow: 0 0 8px #AEB0B5;
      }

      i {
        font-size: 30px;
      }

      span {
        color: #4C2C92;
        font-weight: 600;
      }
    }

    .leads-settings {
      background-color: #DEDFE1;
      color: #5B616B;
      height: 74px;
      text-align: center;
      width: 80px;

      &:hover {
        box-shadow: 0 0 8px #AEB0B5;
      }

      i {
        font-size: 30px;
      }

      span {
        color: #5B616B;
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  #layout-right-sidebar {
    top: 105px;
  }
}
