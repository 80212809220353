app-breadcrumb {
  position: sticky;
  top: 60px;
  z-index: 1;
}

.layout-wrapper {
  .layout-main {
    @include transition(margin-left $transitionDuration);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    .route-bar {
      background-color: $routeBarBgColor;
      border-bottom: solid 1px $routeBarBorderColor;
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;

      &-breadcrumb {
        i {
          color: $routeBarIconColor;
          font-size: 18px;
          vertical-align: bottom;
        }

        a {
          color: $textColor;
        }
      }

      &-menu {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {

          i {
            color: $routeBarIconColor;
            font-size: 18px;
            line-height: inherit;
          }

          a {
            display: block;
            font-size: 18px;
            height: 100%;
            line-height: 30px;
            text-align: center;
            width: 36px;

            &:hover {
              background-color: $routeBarIconHoverBgColor;
            }
          }

          &.search-item {
            input {
              border: none;
              font-size: $fontSize;
              margin-right: 9px;
              max-width: 150px;
              outline: 0 none;
              outline-offset: 0;
              text-align: left;
            }

            i {
              line-height: 30px;
              text-align: center;
              width: 36px;
            }
          }
        }
      }
    }

    .layout-main-content {
      flex: 1 1 0;
      padding: 15px;
    }
  }

  &.layout-wrapper-sidebar-inactive {
    .layout-main {
      margin-left: 0;
    }
  }
}

@media screen and (min-width: $mobileBreakPoint + 1) {
  .layout-wrapper {
    .layout-main {
      margin-left: 230px;
      padding-top: 60px;
    }

    &.layout-wrapper-sidebar-inactive {
      .layout-main {
        margin-left: 0;
      }
    }

    &.layout-wrapper-overlay-sidebar {
      .layout-main {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: $mobileBreakPoint) {
  .layout-wrapper {
    .layout-main {
      margin-left: 0;
      padding-top: 60px;
    }
  }
}
