/* Utils */

.clearfix:after {
  clear: both;
  content: ' ';
  display: block;
}

.card {
  @include border-radius(2px);
  background: #FFF;
  box-sizing: border-box;
  margin-bottom: 16px;
  padding: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .card-header {
    @include flex();
    @include flex-align-center();
    @include flex-justify-between();
  }

  .card-subtitle {
    color: $textSecondaryColor;
    font-weight: 600;
    margin: -1rem 0 1rem 0;
  }
}

.p-toast {
  &.p-toast-top-right,
  &.p-toast-top-left,
  &.p-toast-top-center {
    top: 70px;
  }
}


.fadeInDown {
  -webkit-animation: fadeInDown $transitionDuration;
  animation: fadeInDown $transitionDuration;
}

.fadeOutUp {
  -webkit-animation: fadeOutUp $transitionDuration;
  animation: fadeOutUp $transitionDuration;
}

.flag-rounded{
  border-radius: 4px;
  height: 16px;
  overflow: hidden;
  width: 22px;

}

pre {
  font: 12px monospace;

  &.floating {
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 1px 1px 2px #111;
    max-width: 30vw;
    overflow: hidden;
    padding: 10px;
    position: fixed;
    right: 10px;
    text-overflow: ellipsis;
    top: 80px;
    z-index: 1000;

    &.left {
      right: auto;
      left: 10px;
    }
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
