.invoice {
  .invoice-header {
    margin-top: 15px;
  }

  .invoice-details {
    @include flex();
    @include flex-wrap(wrap);

    > div {
      padding-bottom: 4px;
      padding-top: 4px;
      width: 50%;
    }

    > div:nth-last-child(n+3) {
      border-bottom: 1px solid $dividerColor;
    }

    .invoice-label {
      font-weight: 700;
      text-transform: uppercase;
    }

    .invoice-value {
      text-align: right;
    }
  }

  .invoice-to {
    border-top: 1px solid $dividerColor;
    margin-top: 1.5rem;
    padding-top: 2rem;

    .bill-to {
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: .5rem;
    }

    .invoice-to-info {
      div {
        margin-bottom: .5rem;
      }
    }
  }

  .invoice-items {
    table {
      border-collapse: collapse;
      width: 100%;

      tr {
        border-bottom: $tableBodyCellBorder;
      }

      th {
        font-weight: 700;
      }

      th,
      td {
        padding: 1rem;
        text-align: right;
      }

      td:first-child,
      th:first-child {
        text-align: left;
      }
    }
  }

  .invoice-summary {
    @include flex();
    @include flex-justify-between();
    margin-top: 2.5rem;
    padding-top: 2.5rem;

    .invoice-value {
      font-weight: 700;
    }
  }

  .p-datatable {
    td.mobile:nth-child(odd) {
      background: $tableHeaderCellBg;
    }

    td:not(:first-child),
    th:not(:first-child) {
      text-align: right;
    }

    tr {
      td,
      th {
        border: none;
      }

      &:not(.mobile):nth-child(even) {
        background: $tableHeaderCellBg;
      }
    }

    tbody tr:last-child:nth-child(odd):not(.mobile) {
      border-bottom: $tableBodyCellBorder;
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  #invoice-content * {
    visibility: visible;
  }

  #invoice-content {
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}



